html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
$authBackgroundColour: rgb(252, 226, 234);
@import "bootstrap/scss/bootstrap";
@import "quill/dist/quill.snow.css";

@import "./custom.scss";

form, .container-fluid {
  .fullWidth {
    width: 100%
  }
  .halfWidth{
    width: 50%
  }
}
.app-container {
  height: 100%;
}
.auth-container {
	height: 100%;
  background-color: $authBackgroundColour;
  width: 100%;
}
.datetime-picker-container {
  .mat-form-field-underline {
    bottom: 0px;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0px;
  }
}